function isElementInViewport(el) {
    const rect = el.getBoundingClientRect()
    const fitsLeft = (rect.left >= 0 && rect.left <= $(window).width());
    const fitsTop = (rect.top >= 0 && rect.top <= $(window).height());
    const fitsRight = (rect.right >= 0 && rect.right <= $(window).width());
    const fitsBottom = (rect.bottom >= 0 && rect.bottom <= $(window).height());
    return {
        top: fitsTop,
        left: fitsLeft,
        right: fitsRight,
        bottom: fitsBottom,
        all: (fitsLeft && fitsTop && fitsRight && fitsBottom)
    };
}
